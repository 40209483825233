
import { Options, Vue } from 'vue-class-component'
import PlanDetail from '@/components/PlanDetail.vue'
import service from '@/utils/request'
import { Dialog, Notify } from 'vant'
import NaviBar from '@/components/NaviBar.vue'

@Options({
  components: {
    PlanDetail,
    NaviBar
  }
})
export default class UserHeader extends Vue {
  checked = '1'
  planId = ''

  mounted () {
    this.planId = this.$route.params.planId as string
  }

  onClickLeft () {
    this.$router.go(-1)// 返回上一层
  }

  tapFinish () {
    Dialog.confirm({
      title: '是否确定？',
      message: '确认后将结束该计划',
      confirmButtonText: '结束计划'
    })
      .then(() => {
        // on confirm
        this.finishRequest()
      })
      .catch(() => {
        // on cancel
      })
  }

  finishRequest () {
    service.post('/mindup/mini/plans/' + this.planId + '/finish', {}).then(res => {
      Notify({
        type: 'success',
        message: '通知内容',
        duration: 2000
      })
      setTimeout(() => {
        this.$router.push({ name: 'plan' })
      }, 2000)
    })
  }
}
